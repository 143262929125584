<script setup>
import { defineProps } from "vue";
// Avatar
import avatarDark from '@/assets/images/avatar/default-dark.png'
import avatarLight from '@/assets/images/avatar/default-light.png'
// Signout
import FirebaseAuth, { signOut } from '@/firebase/Auth'

defineProps(['colorMode'])

const logout = () => {
  signOut(FirebaseAuth).then(() => {
    // None
  }).catch((error) => {
    // An error happened.
    console.log('ERRROR AL CERRARRR=??????', error);
  })
}

</script>

<template>
  <CDropdown placement="bottom-end" variant="nav-item">
    <CDropdownToggle class="py-0 pe-0" :caret="false">
      <CAvatar v-if="colorMode === 'light'" :src="avatarLight" size="md" />
      <CAvatar v-else :src="avatarDark" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold mb-2 rounded-top"
      >
        {{ $t('header.dropdown.shortcuts') }}
      </CDropdownHeader>
      <CDropdownItem>
        <RouterLink class="nav-link" :to="{ name: 'Tracing' }">
          <CIcon icon="cil-star" />
          {{ $t('header.dropdown.watchlist') }}
        </RouterLink>
      </CDropdownItem>
      <CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold my-2"
      >
        {{ $t('header.dropdown.settings') }}
      </CDropdownHeader>
      <CDropdownItem>
        <RouterLink class="nav-link" :to="{ name: 'MyAccount' }">
          <CIcon icon="cil-user" />
          {{ $t('header.dropdown.myAccount') }}
        </RouterLink>
      </CDropdownItem>
      <CDropdownItem disabled>
        <CIcon icon="cil-dollar" />
        {{ $t('header.dropdown.billing') }}
        <CBadge color="info">{{ $t('general.badges.soon') }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem href="#" @click="logout">
        <CIcon icon="cil-lock-locked" />
        {{ $t('header.dropdown.logout') }}
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>
